<template>
    <div v-html ='apply'>
             {{apply}}
    </div>
</template>

<script>
     export default {
            name:'applyText',
             data(){
                  return {
                     apply:''
                 }
          },
  mounted(){
    let currentQuery = this.$route.query;
    this.apply = currentQuery.from;
    this.$nextTick(()=> {
      document.forms[0].acceptCharset='GBK'
      document.forms[0].submit()
    })
  }
}
</script>

<style scoped>
</style>